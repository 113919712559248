<template>
    <div class="app-body">
        <div class="a-flex-rsbc">


            <bread-crumb></bread-crumb>
            <div v-if="canEditBtn">
                <el-button type="danger" icon="el-icon-close" plain @click="handlerDelete">删除项目</el-button>
                <el-button type="primary" icon="el-icon-refresh" plain @click="handleChange" v-if="$_has(26) && company.isSystem && (userInfo.dataPermission == 99 || userInfo.dataPermission == 88)">
                    变更项目
                </el-button>
            </div>
        </div>
        <div class="projectDetail">
            <div class="projectDetail-top a-flex-csbfs ">
				<project-detail-base :incomeId="id"></project-detail-base>
                
                <div class="a-mt-32 projectDetail-top-tabs a-w-100">
                    <el-tabs v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane label="统计信息" name="tjxx">
							<project-detail-order-stat v-if="activeName == 'tjxx'" :incomeId="id"></project-detail-order-stat>
                        </el-tab-pane>
                        <el-tab-pane label="分成比例" name="fcbl">
							<project-detail-rate :incomeId="id"></project-detail-rate>
                        </el-tab-pane>
                        <el-tab-pane label="合同信息" name="htxx">
                            <div class="htxx a-p-24 a-bg-white a-w-100 a-flex-rsbc a-flex-wrap">
                                <div>
                                    <span class="a-c-second">项目关联现勘</span>
                                    <span class="a-ml-16 a-cursor-p" @click="handlerSceneDetail">{{
                                            detail.surveyAreaName
                                    }}</span>
                                </div>
                                <div>
                                    <span class="a-c-second">生效时间</span>
                                    <span class="a-ml-16">{{ detail.startTimeText }}</span>
                                </div>
                                <div>
                                    <span class="a-c-second">结束时间</span>
                                    <span class="a-ml-16">{{ detail.endTimeText }}</span>
                                </div>
                                <div class="a-flex-rfsc">
                                    <span class="a-c-second">项目合同</span>
                                    <div class="a-ml-16">
                                        <template v-if="detail.contractFile">
                                            <span>{{ detail.contractFileText }}</span>
                                            <a :href="detail.contractFile">下载
                                                <i class="el-icon-download"></i>
                                            </a>
                                        </template>
                                        <template v-else>
                                            <span>无</span>
                                        </template>

                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                        <!-- <el-tab-pane label="电费信息" name="dfxx">
                            <le-date-changeTime :activeT.sync="dfactiveT" :startTime.sync="startTime"
                                :endTime.sync="endTime"></le-date-changeTime>
                            <div class="tjxx-echarts a-mt-5 a-w-100 projectDetail-box">
                                <div class="tjxx-echarts-title a-fw-700 ">
                                    {{ tjxxTimeText[dfactiveT] }}耗电量统计
                                </div>
                                <div class="tjxx-echarts-box">
                                    <div class="a-flex-rfsfs a-w-100">
                                        <div class="a-flex-cfsfs">
                                            <span class="a-c-normal">总耗电量</span>
                                            <div class="a-flex-rfsc a-mt-10">
                                                <div class="a-fw-700">
                                                    <span class="a-fs-20">{{ detailEnergy.thisTimeEnergy
                                                    }}&nbsp;kwh</span>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="a-flex-cfsfs a-ml-100">
                                            <span class="a-c-normal">电费承担方</span>
                                            <div class="a-flex-ccc a-mt-10">
                                                <div class="" style="line-height: 28px;">
                                                    {{ detail.electricCompanyName }}
                                                </div>

                                            </div>
                                        </div>
                                        <div class="a-flex-cfsfs a-ml-100">
                                            <span class="a-c-normal">电费单价</span>
                                            <div class="a-flex-ccc a-mt-10">
                                                <div class="" style="line-height: 28px;">
                                                    1元/kwh
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <le-echarts-line :echartsObj="echartsObj"></le-echarts-line>

                                </div>

                            </div>
                        </el-tab-pane> -->
                        <el-tab-pane label="站点信息" name="zdxx" class="zdxx" v-if="$_has(21)">
                            <!-- <project-detail-station-list :incomeId="id"></project-detail-station-list> -->
                            <income-station class="stationContent" :params="{
                                incomeId: id
                            }"></income-station>
                        </el-tab-pane>
                        <el-tab-pane label="操作历史" name="czls" class="zdxx">
                            <project-detail-history :incomeId="id"></project-detail-history>
                        </el-tab-pane>
                        <el-tab-pane label="合同变更记录" name="htbgjl" class="zdxx">
                            <project-detail-history-income :incomeId="id"></project-detail-history-income>
                        </el-tab-pane>
                    </el-tabs>

                </div>
            </div>
        </div>
    </div>
</template>
<script>

import {
    mapGetters,
    mapActions,
    mapState
} from 'vuex';
import util from '../../../src/utils/util'
import leDateChangeTime from '../components/form/le-date-changeTime.vue'
import ProjectDetailRate from './project-detail-rate.vue'
import ProjectDetailBase from './project-detail-base.vue'
import PorjectDetailStationList from './project-detail-station-list.vue'
import PorjectDetailHistory from './project-detail-history.vue'
import ProjectDetailHistoryIncome from './project-detail-history-income.vue'
import ProjectDetailOrderStat from './project-detail-order-stat.vue'
import IncomeStation from '../businessAnalysis/info-child/income-station.vue';
export default {
    components: {
        'le-date-changeTime': leDateChangeTime,
		'project-detail-rate' :ProjectDetailRate,
		'project-detail-base' :ProjectDetailBase,
		'project-detail-station-list' :PorjectDetailStationList,
		'project-detail-history':PorjectDetailHistory,
		'project-detail-history-income':ProjectDetailHistoryIncome,
		'project-detail-order-stat':ProjectDetailOrderStat,
        IncomeStation
    },

    data() {
        return {
            companyMaxRate: 100,
            targetList: null,
            util: util,
            activeT: 1,
            startTime: '',
            endTime: '',
            id: null,
            detail: {},
            href: '',
            detailAmout: {},
            detailEnergy: {},
            activeName: 'tjxx',
            tjxxTime: 0,
            dfactiveT: 1,
            tjxxTimeText: ['周', '月', '年', '时间段'],
            input2: '',
            modeltype: 2,
            incomeList: null,
            echartsObj: {
                id: 'xxecharts',
                xAxisData: [],
                seriesData0: [],
                seriesData1: [],
                seriesName1: '订单量',
            },
            pageParam: [],
            tableDataZDXX: [],
            tableDataCZLS: [],
            tableDataHTBGJL: [],
            canEditBtn: false

        }
    },
    created() {
        this.getDetail()
    },
    mounted() {
        // this.echartsInit()

    },
    watch: {
        startTime: {
            handler(val) {
                if (val && !!this.detail.companyId && this.activeName == 'tjxx') {
                    this.getStatOrderEchartsData(this.detail.companyId)
                    this.getAmountTotal()


                }
                if (this.activeName == 'dfxx') {
                    this.getStatEnergyEchartsData(this.detail.companyId)
                    this.getEnergyTotal()
                }
            }
        }
    },
    computed: {
        ...mapState({
            company: state => state.company.company,
            userInfo: state => state.user.user,
        })
    },
    methods: {
        setZdxxTableData(data) {
            this.tableDataZDXX = data

        },
        setHistoryTableDate(data) {
            this.tableDataCZLS = data
        },
        setHTTableDate(data) {
            if (data.isChanging) {
                this.tableDataHTBGJL = data
            }

        },


        getDetail() {
            this.id = parseInt(this.$route.query.id)

            this.$Axios._get({
                url: this.$Config.apiUrl.getIncomeDetailInfo,
                params: {
                    incomeId: parseInt(this.id)
                }
            }).then((res) => {
                console.log('id', this.company.id)
                console.log(res)
                if (res.result.code == 0) {
                    let that = this
                    let data = res.result.data
                    this.canEditBtn = data.companyId == this.company.id ? true : false;
                    this.detail = res.result.data
                    if (res.result.data.contractFile) {
                        let d = data.contractFile.split('/')

                        this.detail.contractFileText = d[d.length - 1];
                    }
                    // fetch(data.contractFile).then(res => res.blob()).then(blob => {
                    //     this.href = URL.createObjectURL(blob)
                    //     console.log(this.href)

                    // })


                    let num = this.detail.targetList.filter((item, index) => {
                        return item.targetCompanyId == this.detail.companyId
                    }).map((oitem, oindex) => {
                        return oitem.rate
                    }).reduce(function (prev, cur, index, arr) {
                        console.log(prev, cur, index);
                        return parseInt(prev) + parseInt(cur);
                    })
                    this.detail.merchantIncome = num / 100

                    // this.companyRate(res.result.data.companyId)
                    this.getStatOrderEchartsData(res.result.data.companyId)
                    this.getAmountTotal(res.result.data.companyId)
                    this.getEnergyTotal(res.result.data.companyId)
                    // this.getStatEnergyEchartsData(res.result.data.companyId)


                    if (data.rangeList) {
                        this.targetList = data.targetList.map((item, index) => {
                            if (item.testRun != 1) {
                                data.rangeList.map((oitem, oindex) => {
                                    if (item.rateRangeId == oitem.id) {
                                        item.maxAmount = oitem.maxAmount,
                                            item.minAmount = oitem.minAmount
                                    }
                                })
                            }
                            return item
                        })
                    } else {
                        this.targetList = data.targetList
                    }




                    console.log(this.targetList)

                    function unique(arr, val) {
                        const res = new Map()
                        return arr.filter(item => !res.has(item[val]) && res.set(item[val], 1))
                    }


                    let list = unique(that.targetList, 'targetCompanyId')
                    let result = list.map((item, index) => {
                        let olist = that.targetList.filter((oitem, oindex) => {
                            return oitem.targetCompanyId == item.targetCompanyId
                        })

                        console.log(olist)
                        let c;
                        olist.map((item, index) => {
                            if (!!item.testRun) {
                                if (!!item.rate) {
                                    c = item.rate
                                }
                                olist.splice(index, 1)
                            }

                        })

                        let obj = {
                            targetCompanyId: item.targetCompanyId,
                            targetCompanyName: item.targetCompanyName,
                            rate: c || 0,
                            list: olist
                        }

                        return obj
                    })
                    this.incomeList = result;

                }
            })
        },
        
        handleClick(tab, event) {
            // let that = this
            // if (tab.index == '3') {
            //     this.getStatEnergyEchartsData(this.detail.companyId)

            // }
            // if (tab.index == '0') {
            //     this.getStatOrderEchartsData(this.detail.companyId)

            // }



        },
        //echarts图表
        echartsInit() {
            // this.$echarts.init(document.getElementById('tjxxEcharts')).setOption(this.echartOptions, true)

        },
        //获取收益趋势图表的数据
        getStatOrderEchartsData(id) {
            this.$Axios._post({
                url: this.$Config.apiUrl.getStatOrderAmoutList,
                params: {
                    "currentCompanyId": id,
                    "incomeId": this.detail.id,
                    "queryStartDate": this.startTime,
                    "queryEndDate": this.endTime,
                    "statIncome": 1
                }
            }).then((res) => {
                console.log(res)
                let that = this
                if (res.result.code == 0) {

                    this.echartsObj.xAxisData = []
                    this.echartsObj.seriesData0 = []
                    this.echartsObj.seriesData1 = []
                    res.result.data.map((item, index) => {
                        this.echartsObj.xAxisData.push(item.fullDateText)
                        this.echartsObj.seriesData0.push(item.orderNum)
                        this.echartsObj.seriesData1.push(item.orderAmount)

                    })


                }

            })
        },
        //获取电费趋势图表的数据
        getStatEnergyEchartsData(id) {
            this.$Axios._post({
                url: this.$Config.apiUrl.getStatEnergyList,
                params: {
                    // "currentCompanyId": id,
                    "incomeId": this.detail.id,
                    "queryStartDate": this.startTime,
                    "queryEndDate": this.endTime,
                    "statIncome": 1
                }
            }).then((res) => {
                console.log(res)
                let that = this
                if (res.result.code == 0) {
                    this.echartsObj.id = 'dfqst'
                    this.echartsObj.xAxisData = []
                    this.echartsObj.seriesData0 = []
                    this.echartsObj.seriesData1 = []
                    res.result.data.map((item, index) => {
                        this.echartsObj.xAxisData.push(item.fullDateText)
                        this.echartsObj.seriesData0.push(item.orderNum)
                        this.echartsObj.seriesData1.push(item.energy)

                    })


                }

            })
        },



        getAmountTotal(id) {
            this.$Axios._post({
                url: this.$Config.apiUrl.getStatOrderAmountTotal,
                params: {
                    // "currentCompanyId": id,
                    "incomeId": this.detail.id,
                    "queryStartDate": this.startTime,
                    "queryEndDate": this.endTime,
                    "statIncome": 1
                }
            }).then((res) => {
                console.log(res)
                let that = this
                if (res.result.code == 0) {
                    let amount = res.result.data.thisTimeOrderAmount - res.result.data.lastTimeOrderAmount
                    let num = res.result.data.thisTimeOrderNum - res.result.data.lastTimeOrderNum

                    this.detailAmout = {
                        ...res.result.data,
                        difAmount: amount < 0 ? '- ¥' + util.numFormat(amount) : '+ ¥' + util.numFormat(amount),
                        difNum: num < 0 ? '-' + num : '+' + num
                    }

                }

            })
        },
        getEnergyTotal(id) {
            this.$Axios._post({
                url: this.$Config.apiUrl.getStatEnergyTotal,
                params: {
                    // "currentCompanyId": id,
                    "incomeId": this.detail.id,
                    "queryStartDate": this.startTime,
                    "queryEndDate": this.endTime,
                    "statIncome": 1
                }
            }).then((res) => {
                console.log(res)
                let that = this
                if (res.result.code == 0) {

                    this.detailEnergy = res.result.data

                }

            })
        },
        
        handlerGoDetail(id, type) {
            let jumpurl;
            if (type == 'HT') {
                jumpurl = '/project/project-detail'
            }

            this.$router.push({
                path: jumpurl,
                query: {
                    id: id
                }
            })
        },
        // download(url) {
        //     fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
        //         const a = document.createElement('a')
        //         a.href = URL.createObjectURL(blob)
        //         //测试链接console.log(a.href)
        //         a.download = 'fileName.xlsx'  // 下载文件的名字
        //         document.body.appendChild(a)
        //         a.click()
        //     })
        // },
        //变更项目
        handleChange() {
            this.$router.push({
                path: '/project/project-add',
                query: {
                    incomeId: this.detail.id
                }
            })
        },
        //删除项目
        handlerDelete() {
            this.$confirm('确定删除该项目?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$Axios._get({
                    url: this.$Config.apiUrl.delIncome,
                    params: {
                        incomeId: this.detail.id
                    }
                }).then((res) => {
                    if (res.result.code == 0) {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        setTimeout(() => {
                            this.$router.push({
                                path: '/project/project-list'
                            })
                        }, 1000)
                    }
                })
            }).catch(() => {
            });
        },
        //跳转现勘详情
        handlerSceneDetail() {
            this.$router.push({
                path: '/sceneCheck/sceneCheck-info',
                query: {
                    id: this.detail.surveyAreaId,
                    title: this.detail.surveyAreaName
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.projectDetail::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
.projectDetail {
    font-size: 14px;
    height: 100%;
    overflow-y: auto;

    >div {
        border-radius: 4px;
    }

    &-box {
        border-radius: 4px;
        background: #fff;
    }

    &-top {
        background: #fff;
        padding: 24px;
        padding-bottom: 64px;
        position: relative;

        &-item {
            display: flex;
            flex-direction: row;
            width: 33.3%;
            margin-top: 16px;
            font-size: 14px;
            line-height: 22px;

            >div {
                // width: 116px;
                margin-right: 16px;
            }

            // span {
            //     margin-left: 16px;
            // }
        }

        &-tabs {
            position: absolute;
            top: 132px;
            left: 0;

            .tjxx {
                // &-tabs {
                //     span {
                //         border: 1px #DADDE0 solid;
                //         border-left: 1px #fff solid;
                //         background: #fff;
                //         height: 32px;
                //         display: flex;
                //         flex-flow: column;
                //         justify-content: center;
                //         width: 64px;
                //         text-align: center;
                //         cursor: pointer;

                //         &:hover {
                //             background: #f9f9f9;
                //         }

                //         &:nth-of-type(1) {
                //             border-radius: 4px 0 0 4px;
                //             border-left: 1px #DADDE0 solid;
                //         }

                //         &:nth-last-of-type(1) {
                //             border-radius: 0 4px 4px 0;
                //         }

                //         &.active {
                //             border: 1px solid #007AFF;
                //             color: #007AFF;
                //         }
                //     }
                // }

                &-echarts {
                    &-title {
                        width: 100%;
                        padding: 16px 25px;
                        border-bottom: 1px solid #EBF0F5;
                        font-size: 16px;
                    }

                    &-box {
                        padding: 15px 24px 10px;
                    }
                }
            }

            .fcbl {
                padding: 24px;
                background: #fff;
                border-radius: 4px;

                &-table {
                    margin-top: 16px;
                    width: 100%;
                    border: 1px solid #DADDE0;
                    padding: 0 16px 12px;

                    >div {
                        width: 100%;

                        &:nth-of-type(1) {
                            border: none;
                            padding: 24px 0;
                        }

                        &:nth-of-type(2) {
                            background: #F5F7FA;
                        }

                        padding:12px 0;
                        border-bottom:1px solid #FAFBFC;
                        display: flex;
                        flex-flow: row;
                        justify-content: space-between;
                        align-items: center;

                        >* {
                            width: 50%;
                            text-align: center;

                        }
                    }
                }
            }

            .htxx {
                background: #fff;
                border-radius: 4px;

                >div {
                    width: 33.33%;
                    margin-bottom: 16px;

                    a {
                        text-decoration: none;
                        color: #007AFF;
                        margin-left: 8px;
                    }
                }
            }


        }
    }

    /deep/ .el-tabs__item {
        // color: #007aff;
        font-size: 14px;
    }

    /deep/ .el-tabs__item.is-active {
        color: #007AFF;
        font-weight: bold;
    }

    /deep/ .el-tabs__nav-wrap::after {
        background: none !important;
    }

    /deep/ .el-tabs__header {
        margin-left: 24px;
    }

    .zdxx {
        background: #fff;
        padding: 10px;

        &-table {
            margin-top: -24px;
            background: #fff;
            width: 100%;
        }
    }
    .stationContent{
        margin-top: 0 !important;
        /deep/ .headBox{
            padding-top: 0 !important;
        }
    }
}
</style>