<template>
	<div class="a-w-100 a-h-100">
		<div class="a-flex-rfsfs">
			<span class="a-fs-16 a-fw-700">{{ detail.name }}</span>
	
			<el-tag class="a-ml-08" v-if="detail.isChanging" type="danger">变更中</el-tag>
			<el-tag class="a-ml-08" v-else>运行{{ detail.runDays }}天</el-tag>
		</div>
		<div class="a-mt-16 a-flex-rfsc a-flex-wrap a-w-100">
			<div class="projectDetail-top-item">
				<div>所属商户：</div>
				<a href="" class="a-c-blue">{{ detail.companyName }}</a>
			</div>
			<div class="projectDetail-top-item">
				<div>所属商户收益：</div>
				<span>{{ detail.maxRate == detail.minRate?(detail.maxRate/100):(detail.minRate/100+' ~ '+detail.maxRate/100) }}%</span>
			</div>
			<div class="projectDetail-top-item">
				<div>最大可分配收益：</div>
				<span>{{ companyMaxRate }}%</span>
			</div>
			<div class="projectDetail-top-item">
				<div>默认站点定价：</div>
				<span>{{ detail.defaultPlanName?detail.defaultPlanName:'-' }}</span>
			</div>
			<div class="projectDetail-top-item">
				<div>入账类型：</div>
				<span>{{ detail.incomeType | initDic(incomeTypeDic) }}</span>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapState,
		mapActions
	} from 'vuex';
	import util from '../../utils/util.js'
	export default {
		props: {
			incomeId: {
				type: Number,
			},

		},
		computed: {
			...mapState({
				company: state => state.company.company,
			})
		},
		data() {
			return {
				util: util,
				detail: {
				},
				companyMaxRate:0,
				incomeTypeDic: []
			}
		},
		watch: {

			incomeId: {
				handler(val) {
					if (val) {
						this.getIncomeDetail();
					}
				},
				immediate: true
			},

		},
		created () {
			this.$getDic('incomeType').then(res=>{ this.incomeTypeDic = res; })
		},
		methods: {

			/**
			 * 获取项目详情
			 */
			getIncomeDetail() {
				console.log('float-rage components getIncomeDetail')
				this.$Axios._get({
						url: this.$Config.apiUrl.gitEditIncomeDetailInfo,
						params: {
							incomeId: this.incomeId,
						},
					})
					.then(({
						data
					}) => {
						this.detail = data
						this.companyRate(data.companyId)
					});
			},
			
			companyRate(id) {
			
			    this.$Axios._get({
			        url: this.$Config.apiUrl.getCompanyMaxRate,
			        params: {
			            companyId: id
			        }
			    }).then((res) => {
			        this.companyMaxRate = res.result.data / 100
			
			    })
			},
			

		}
	}
</script>

<style lang="scss" scoped>
	.projectDetail {
		font-size: 14px;
		height: 100%;
		overflow-y: auto;

		>div {
			border-radius: 4px;
		}

		&-box {
			border-radius: 4px;
			background: #fff;
		}

		&-top {
			background: #fff;
			padding: 24px;
			padding-bottom: 64px;
			position: relative;

			&-item {
				display: flex;
				flex-direction: row;
				width: 33.3%;
				margin-top: 16px;
				font-size: 14px;
				line-height: 22px;

				>div {
					// width: 116px;
					margin-right: 16px;
				}

				// span {
				//     margin-left: 16px;
				// }
			}

			&-tabs {
				position: absolute;
				top: 132px;
				left: 0;

				.tjxx {
					// &-tabs {
					//     span {
					//         border: 1px #DADDE0 solid;
					//         border-left: 1px #fff solid;
					//         background: #fff;
					//         height: 32px;
					//         display: flex;
					//         flex-flow: column;
					//         justify-content: center;
					//         width: 64px;
					//         text-align: center;
					//         cursor: pointer;

					//         &:hover {
					//             background: #f9f9f9;
					//         }

					//         &:nth-of-type(1) {
					//             border-radius: 4px 0 0 4px;
					//             border-left: 1px #DADDE0 solid;
					//         }

					//         &:nth-last-of-type(1) {
					//             border-radius: 0 4px 4px 0;
					//         }

					//         &.active {
					//             border: 1px solid #007AFF;
					//             color: #007AFF;
					//         }
					//     }
					// }

					&-echarts {
						&-title {
							width: 100%;
							padding: 16px 25px;
							border-bottom: 1px solid #EBF0F5;
							font-size: 16px;
						}

						&-box {
							padding: 15px 24px 10px;
						}
					}
				}

				.fcbl {
					padding: 24px;
					background: #fff;
					border-radius: 4px;

					&-table {
						margin-top: 16px;
						width: 100%;
						border: 1px solid #DADDE0;
						padding: 0 16px 12px;

						>div {
							width: 100%;

							&:nth-of-type(1) {
								border: none;
								padding: 24px 0;
							}

							&:nth-of-type(2) {
								background: #F5F7FA;
							}

							padding:12px 0;
							border-bottom:1px solid #FAFBFC;
							display: flex;
							flex-flow: row;
							justify-content: space-between;
							align-items: center;

							>* {
								width: 50%;
								text-align: center;

							}
						}
					}
				}

				.htxx {
					background: #fff;
					border-radius: 4px;

					>div {
						width: 33.33%;
						margin-bottom: 16px;

						a {
							text-decoration: none;
							color: #007AFF;
							margin-left: 8px;
						}
					}
				}


			}
		}

		/deep/ .el-tabs__item {
			// color: #007aff;
			font-size: 14px;
		}

		/deep/ .el-tabs__item.is-active {
			color: #007AFF;
			font-weight: bold;
		}

		/deep/ .el-tabs__nav-wrap::after {
			background: none !important;
		}

		/deep/ .el-tabs__header {
			margin-left: 24px;
		}

		.zdxx {
			background: #fff;
			padding: 24px;

			&-table {
				margin-top: -24px;
				background: #fff;
				width: 100%;
			}
		}
	}
</style>
