<template>
	<div>
        <le-pagview @setData="setTableData" :pageParam="pageParam" v-if="pageParam" style="margin-top: 0px !important;">
            <el-table :data="tableData" :highlight-current-row="true" max-height="510" class="zdxx-table">
                <el-table-column label="操作时间">
                    <template slot-scope="{ row }">
                        <span> {{ row.opDateStr }}&nbsp;{{ row.opTimeStr }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="description" label="操作内容" width="600">
                    <template slot-scope="{ row }">
                        <span> {{ row.comment || '' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="description" label="变更后内容">
                    <template slot-scope="{ row }">
                        <span class="a-c-blue font-point" @click="showChangeContent(row)" v-if="row.newDetail || row.oldDetail">查看</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column prop="address" label="当前状态">
                    <template slot-scope="{ row }">
                        <span> {{ row.toStateText || '' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="opUserRealName" label="操作人">
                    <template slot-scope="{ row }">
                        <span> {{ row.opUserRealName || '' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="description" label="备注">
                    <template slot-scope="{ row }">
                        <span> {{ row.description || '' }}</span>
                    </template>
                </el-table-column>
            </el-table>
        </le-pagview>
        <el-dialog
            title="项目变更内容"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="800px">
            <div class="formContent">
                <el-table :data="changeTableData">
                <el-table-column label="">
                    <template slot-scope="{ row }">
                        <span>{{ row.changeItemName || '-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="description" label="修改前">
                    <template slot-scope="{ row }">
                        <span v-if="row.keyVal != 'targetList'">{{ row.oldVal || '-' }}</span>
                        <div v-else class="a-flex-cfsfs a-w-100" style="align-items: flex-start">
                            <span v-for="(item,index) in row.oldVal" :key="index">{{ item.targetCompanyName }}，比例：{{ (item.rate / 100).toFixed(2) }}%</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="description" label="修改后">
                    <template slot-scope="{ row }">
                        <span v-if="row.keyVal != 'targetList'">{{ row.newVal || '-' }}</span>
                        <div v-else class="a-flex-cfsfs a-w-100" style="align-items: flex-start">
                            <span v-for="(item,index) in row.newVal" :key="index">{{ item.targetCompanyName }}，比例：{{ (item.rate / 100).toFixed(2) }}%</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            </div>
            <div slot="footer">
                <el-button type="primary" @click="dialogVisible = false">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
	import {
		mapState,
		mapActions
	} from 'vuex';
    import util from '../../utils/util'
	export default {
		props: {
			incomeId: {
				type: Number,
			},
		},
		computed: {
			...mapState({
				company: state => state.company.company,
			})
		},
		data() {
			return {
                util,
				pageParam: '',
				tableData: [],
                changeTableData: [],
                changeContentKeys: {
                    'name':'项目名称',
                    'companyName':'所属商户',
                    'serviceFee':'服务费',
                    'needConfirm':'是否需要三方确认',
                    'incomeType':'入账类型',
                    'defaultPlanName':'计费模板',
                    'electricCompanyName':'所属商户',
                    'electricUnitPrice':'电费单价',
                    'rateType':'分成模式',
                    'contractFile':'项目合同',
                    'surveyAreaName':'合同关联现勘',
                    'startTimeText':'生效日期',
                    'endTimeText':'结束日期',
                    'targetList': '收益方'
                },
                dialogVisible: false,
			}
		},
		watch: {

			incomeId: {
				handler(val) {
					if (val) {
						this.pageParam = {
							url: this.$Config.apiUrl.getIncomeTimeLine,
							method: "get",
							params: {
								incomeId: this.incomeId
							},
							freshCtrl: 1,
						}
					}
				},
				immediate: true
			},

		},
        mounted () {
        },
		methods: {
			setTableData(data) {
				this.tableData = data.data
			},
            showChangeContent (row) {
                try {
                    let changeContent = JSON.parse(row.newDetail)
                    let changeContentOld = JSON.parse(row.oldDetail)
                    let changeTableData = []
                    for (const key in this.changeContentKeys) {
                        if (Object.hasOwnProperty.call(this.changeContentKeys, key)) {
                            let newVal = changeContent[key] || '', oldVal = changeContentOld[key] || ''
                            if(key == 'electricUnitPrice' || key == 'serviceFee') {
                                newVal = newVal ? (newVal / 100).toFixed(2) : '0.00'
                                oldVal = oldVal ? (oldVal / 100).toFixed(2) : '0.00'
                            }
                            if(key == 'incomeType') {
                                newVal = util.initDic(newVal, 'incomeType')
                                oldVal = util.initDic(oldVal, 'incomeType')
                            }
                            if(key == 'needConfirm') {
                                newVal = newVal ? '需要' : '不需要'
                                oldVal = oldVal ? '需要' : '不需要'
                            }
                   
                            let isChange = changeContent[key] == changeContentOld[key] ? false : true
                            if(key == 'targetList') {
                                if(newVal.length != oldVal,length) {
                                    isChange = true
                                }else {
                                    isChange = this.arraysEqual(newVal, oldVal)
                                }
                            }
                            changeTableData.push({
                                changeItemName: this.changeContentKeys[key],
                                keyVal: key,
                                newVal: newVal,
                                oldVal: oldVal,
                                isChange: isChange
                            })
                        }
                    }
                    this.changeTableData = changeTableData.filter(res=>{
                        return res.isChange
                    })
                    this.dialogVisible = true
                } catch (error) {
                    console.log(error);
                }
                
            },
            arraysEqual (arr1, arr2) {
                for (let index = 0; index < arr1.length; index++) {
                    const item = arr1[index];
                    let targetItem = arr2.find(res=>{
                        return res.targetCompanyId == item.targetCompanyId && res.rate == item.rate
                    })
                    if(!targetItem) {
                        return(true)
                    }
                }
                return false
            }
		}
	}
</script>

<style lang="scss" scoped>
    .formContent{
        max-height: 70vh;
        overflow: auto;
    }
</style>
