<template>
	<le-pagview @setData="setTableData" :pageParam="pageParam" v-if="pageParam" style="margin-top: 0px !important;">
		<el-table :data="tableData" :highlight-current-row="true" max-height="510" class="zdxx-table">
			<el-table-column prop="startTimeText" label="变更时间">
				<template slot-scope="{ row }">
					<span>{{ util.allformatDate(row.updateTime) }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="name" label="项目名称">
			</el-table-column>
			<el-table-column prop="startTimeText" label="生效时间">
			</el-table-column>
			<el-table-column prop="endTimeText" label="到期时间">
			</el-table-column>
			<el-table-column prop="stateText" label="当前状态">
			</el-table-column>
			<el-table-column label="操作" fixed="right">
				<template slot-scope="{ row }">
					<el-tooltip class="item" effect="dark" content="变更前详情" placement="top">
						<div @click="handlerGoDetail(row.changeId, 'HT')">

							<img src="../../assets/icon/option-detail.png" class="a-wh-16" />
						</div>
					</el-tooltip>
				</template>
			</el-table-column>
		</el-table>
	</le-pagview>
</template>

<script>
	import {
		mapState,
		mapActions
	} from 'vuex';
	export default {
		props: {
			incomeId: {
				type: Number,
			},
		},
		computed: {
			...mapState({
				company: state => state.company.company,
			})
		},
		data() {
			return {
				pageParam: '',
				tableData: []
			}
		},
		watch: {

			incomeId: {
				handler(val) {
					if (val) {
						this.pageParam = {
							url: this.$Config.apiUrl.getIncomeChangeRecord,
							method: "get",
							params: {
								incomeId: this.incomeId
							},
							freshCtrl: 1,
						}
					}
				},
				immediate: true
			},

		},
		methods: {
			setTableData(data) {
				this.tableData = data.data
			}
		}
	}
</script>

<style>
</style>
