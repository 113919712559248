<template>
	<div class="a-bg-white a-w-100 a-h-100 projectDetail-top">


		<div class="projectDetail-top-tabs fcbl a-flex-cfsfs " style="max-width: 900px;">
			<template v-if="rateType == 2">
				<div class="fcbl-top">
					<span class="a-c-second">分成模式</span>
					<span class="a-ml-15">根据收益浮动</span>
				</div>
				<div class="fcbl-table a-flex-cfsfs" v-for="(item, index) in detailFrom.detail" :key="index">
					<div class="a-flex-rfsfs">
						<div class="a-flex-rsbc">
							<span class="a-c-second">收益方{{ index + 1 }}</span>
							<span class=" a-flex-1" style="margin-left:-50px">
								{{item.targetCompanyName}}
							</span>
						</div>

						<div v-if="item.testRun == 1">
							<span class="a-c-second">试运行30天分成</span>
							<span class="a-ml-16">{{ item.testRunRate  }}%</span>
						</div>

                        <div>
							<span class="a-c-second">分账模板</span>
							<span class="a-ml-16">{{ item.incomePlanName?item.incomePlanName:'-'  }}</span>
						</div>
					</div>
					<div class="a-c-second">
						<span>连续30天平均单桩收益</span>
						<span>分成比例</span>
					</div>
					<div v-for="(oitem, oindex) in item.rangeDetail" :key="oindex">

						<div>
							<span
								v-if="oitem.minAmount || oitem.minAmount == 0">{{util.numFormat(oitem.minAmount)}}&le;</span>
							单桩日均收益
							<span v-if="oitem.maxAmount">&lt;{{ util.numFormat(oitem.maxAmount)}}</span>
						</div>
						<span>{{ oitem.rate  }}%</span>
					</div>
				</div>

			</template>
			<template v-if="rateType == 1">
				<div class="fcbl-top">
					<span class="a-c-second">分成模式</span>
					<span class="a-ml-15">固定收益比例</span>
				</div>
				<div class="fcbl-table a-flex-cfsfs" style="padding-bottom:0;border:none;"
					v-for="(item, index) in detailFrom.detail" :key="item.id">
					<div class="a-flex-rfsfs">
						<div class="a-flex-rsbc">
							<span class="a-c-second" style="margin-right: 18px">收益方{{ index + 1 }}</span>
							<span class=" a-flex-1">{{ item.targetCompanyName }}</span>
						</div>

						<div>
							<span class="a-c-second">分成比例</span>
							<span class="a-ml-16">{{ item.rate/100  }}%</span>
						</div>

                        <div>
							<span class="a-c-second">分账模板</span>
							<span class="a-ml-16">{{ item.incomePlanName?item.incomePlanName:'-' }}</span>
						</div>
					</div>
				</div>
			</template>

		</div>
	</div>
</template>

<script>
	import {
		mapState,
		mapActions
	} from 'vuex';
	import util from '../../utils/util.js'
	export default {
		props: {
			incomeId: {
				type: Number,
			},

		},
		computed: {
			...mapState({
				company: state => state.company.company,
			})
		},
		data() {
			return {
				util: util,
				detailFrom: {
					detail: [],
				},

				sourceDetail: [], //编辑时从接口返回的原始明细
				rateType: null,

				rangeList: []
			}
		},
		watch: {

			incomeId: {
				handler(val) {
					if (val) {
						this.getIncomeDetail();
					}
				},
				immediate: true
			},

		},
		methods: {

			/**
			 * 获取项目详情
			 */
			getIncomeDetail() {
				console.log('float-rage components getIncomeDetail')
				this.$Axios._get({
						url: this.$Config.apiUrl.gitEditIncomeDetailInfo,
						params: {
							incomeId: this.incomeId,
						},
					})
					.then(({
						data
					}) => {
						this.rateType = data.rateType
						this.sourceDetail = data.targetList
						if (data.rangeList) {
							this.rangeList = data.rangeList.map(item => {
								return {
									_id: item.id,
									...item
								}
							})
						}

						if (this.rateType == 2) {
							this.setRangeRateDetail();
						} else {
							this.detailFrom.detail = data.targetList
						}
					});
			},
			/**
			 * 动态调整收益明细
			 */
			setRangeRateDetail() {


				/**
				 * 从接口返回了初始化数据
				 */
				this.sourceDetail.map(item => {
					let details = this.detailFrom.detail.filter(detail => {
						return item.targetCompanyId == detail.targetCompanyId && item.serNo == detail.serNo
					});

					/**
					 * 是否为新构造的明细
					 */
					let isCreate = details.length == 0
					let detail = !isCreate ? details[0] : {
						targetCompanyId: item.targetCompanyId,
						rate: item.rate / 100,
						testRunRate: 0,
						targetCompanyName: item.targetCompanyName,
						serNo: item.serNo,
						rangeDetail: []
					};

					/**
					 * 如果为新构造的明细则添加到明细中
					 */
					if (isCreate) {
						this.detailFrom.detail.push(detail)
					}
					/**
					 * 原始条目为试运行条目
					 */
					if (item.testRun == 1) {
						detail.testRunRate = item.rate / 100
						this.isOpenTestRun = true
						return;
					}

					/**
					 * 原始数据为浮动收益类型,则先找到匹配的收益区间,然后获取收益区间的ID
					 */
					let range = this.rangeList.filter(range => {
						return range._id == item.rateRangeId
					})[0]
					detail.rangeDetail.push({
						_id: range._id,
						rate: item.rate / 100,
						minAmount: range.minAmount,
						maxAmount: range.maxAmount,
					})

				})

			},

		}
	}
</script>


<style lang="scss" scoped>
	.projectDetail-top {



		background: #fff;
		padding: 24px;
		padding-bottom: 64px;
		position: relative;

		&-item {
			display: flex;
			flex-direction: row;
			width: 33.3%;
			margin-top: 16px;
			font-size: 14px;
			line-height: 22px;

			>div {
				// width: 116px;
				margin-right: 16px;
			}

			// span {
			//     margin-left: 16px;
			// }
		}

		&-tabs {
			//position: absolute;
			top: 132px;
			left: 0;

			.tjxx {
				

				&-echarts {
					&-title {
						width: 100%;
						padding: 16px 25px;
						border-bottom: 1px solid #EBF0F5;
						font-size: 16px;
					}

					&-box {
						padding: 15px 24px 10px;
					}
				}
			}

			.fcbl {
				padding: 24px;
				background: #fff;
				border-radius: 4px;

				&-table {
					margin-top: 16px;
					width: 100%;
					border: 1px solid #DADDE0;
					padding: 0 16px 12px;

					>div {
						width: 100%;

						&:nth-of-type(1) {
							border: none;
							padding: 24px 0;
						}

						&:nth-of-type(2) {
							background: #F5F7FA;
						}

						padding:12px 0;
						border-bottom:1px solid #FAFBFC;
						display: flex;
						flex-flow: row;
						justify-content: space-between;
						align-items: center;

						>* {
							width: 50%;
							text-align: left;

						}
					}
				}
			}

			.htxx {
				background: #fff;
				border-radius: 4px;

				>div {
					width: 33.33%;
					margin-bottom: 16px;

					a {
						text-decoration: none;
						color: #007AFF;
						margin-left: 8px;
					}
				}
			}


		}
	}

	/deep/ .el-tabs__item {
		// color: #007aff;
		font-size: 14px;
	}

	/deep/ .el-tabs__item.is-active {
		color: #007AFF;
		font-weight: bold;
	}

	/deep/ .el-tabs__nav-wrap::after {
		background: none !important;
	}

	/deep/ .el-tabs__header {
		margin-left: 24px;
	}

	.zdxx {
		background: #fff;
		padding: 24px;

		&-table {
			margin-top: -24px;
			background: #fff;
			width: 100%;
		}
	}
</style>
