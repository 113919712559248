<template>
	<le-pagview ref="stationlist" @setData="setTableData" :pageParam="pageParam" v-if="pageParam" style="margin-top: 0px !important;">
	    <el-table :data="tableData" :highlight-current-row="true" max-height="510"
	        class="zdxx-table">
	        <el-table-column prop="name" label="站点名称">
	        </el-table-column>
	        <el-table-column prop="companyName" label="所属商户">
	        </el-table-column>
	        <el-table-column prop="address" label="站点地址" width="350">
	        </el-table-column>
	        <el-table-column label="历史营业额(元)">
	            <template slot-scope="{ row }">
	                <span> &yen;{{ util.numFormat(row.hisOrderAmount) }}</span>
	            </template>
	        </el-table-column>
	        <el-table-column label="今日营业额(元)">
	            <template slot-scope="{ row }">
	                <span>&yen;{{ util.numFormat(row.todayOrderAmount) }}</span>
	            </template>
	        </el-table-column>
	        <el-table-column prop="todayOrderNum" label="今日订单数">
	        </el-table-column>
	        <el-table-column label="在线/总数(台)">
	            <template slot-scope="{ row }">
	                <span>{{
	                        row.onlineDeviceNum
	                }}/{{ row.totalDeviceNum }}</span>
	            </template>
	        </el-table-column>
	        <el-table-column prop="usingDeviceNum" label="正在充电人数">
	        </el-table-column>
	
	        <el-table-column label="操作" fixed="right">
	            <template slot-scope="{ row }">
	                <el-tooltip class="item" effect="dark" content="站点详情" placement="top">
	                    <div @click="handlerGoDetail(row.id, 'ZD')">
	
	                        <img src="../../assets/icon/option-detail.png" class="a-wh-16" />
	                    </div>
	                </el-tooltip>
	            </template>
	        </el-table-column>
	    </el-table>
	</le-pagview>
</template>

<script>
	import util from '../../../src/utils/util'
	import {
		mapState,
		mapActions
	} from 'vuex';
	export default {
		props: {
			incomeId: {
				type: Number,
			},
		},
		computed: {
			...mapState({
				company: state => state.company.company,
			})
		},
		data() {
			return {
				util:util,
				pageParam:'',
				tableData:[]
			}
		},
		watch: {
			
			incomeId: {
				handler(val) {
					if (val) {
						this.pageParam = {
							url: this.$Config.apiUrl.getStationInfoList,
							method: "post",
							params: {
								isSearchAgency: 2,
								incomeId: this.incomeId
							},
							freshCtrl: 1,
						}
					}
				},
				immediate:true
			},
			
		},
		methods: {
			setTableData(data){
				this.tableData = data
			}
		}
	}
</script>

<style>
</style>